import React from 'react'
import moment from 'moment'
import AddReservedDate from '../../General/AddReservedDate'
import ReservedDatesTable from '../../General/ReservedDatesTable'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

export default function EntertainmentReservedDates(props) {
    const [added, setAdded] = React.useState(0)
    const addedFun = ()=>{
        setAdded(added+1)
    }
    const  columns= [
        {
          title: "البداية",
          field: "reservedDates.startAt",
          render: (rowData) => moment(rowData.reservedDates.startAt).format("YYYY/MM/DD"),

        },
        { title: "النهاية", field: "reservedDates.endAt",
        render: (rowData) => moment(rowData.reservedDates.endAt).format("YYYY/MM/DD"),
      }
      ];
    return (
      <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
           <AddReservedDate addedFuncion={addedFun} type="entertainment" section="entertainmentProducts" id={props.match.params.id}/>
           </GridItem>
           <GridItem xs={12} sm={12} md={7}>
           <ReservedDatesTable columns={columns} render={added} type="entertainmentProducts" id={props.match.params.id}/>
           </GridItem>
      </GridContainer>
    )
}
