export  const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export  const LOGIN_FAILURE = 'LOGIN_FAILURE';
export  const LOGOUT = 'LOGOUT';
export  const RESET_ERRORS = 'RESET_ERRORS';
export  const LOGIN_INIT = 'LOGIN_INIT';


export const GLOBAL_PROPS="GLOBAL_PROPS";
export  const GET_CATEGORIES_INIT = 'GET_CATEGORIES_INIT';
export  const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export  const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export  const GET_PRODUCTS_INIT = 'GET_PRODUCTS_INIT';
export  const GET_PRODUCTS_CLEAR = 'GET_PRODUCTS_CLEAR';
export  const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export  const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export  const GET_PRODUCTS_SEARCH = 'GET_PRODUCTS_SEARCH';

export  const GET_PRODUCT_BY_ID_INIT = 'GET_PRODUCT_BY_ID_INIT';
export  const GET_PRODUCT_BY_ID_CLEAR = 'GET_PRODUCT_BY_ID_CLEAR';
export  const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export  const GET_PRODUCT_BY_ID_FAILURE = 'GET_PRODUCT_BY_ID_FAILURE';
