import React from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard/Dashboard";


function EventsDashboard({auth}) {
  
  return (
     auth.isAuth?
   <Dashboard type="Events" routeType='bookEvent'/>
 :null
 );
}


const mapStateToProps = (state) => ({
    auth:state.auth
});

const mapDispatchToProps = {
 
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsDashboard);
