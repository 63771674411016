import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MicIcon from "@material-ui/icons/Mic";
import HotelIcon from "@material-ui/icons/Hotel";
import RowingIcon from '@material-ui/icons/Rowing';
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import RTL from "./RTL";
import { Link } from "react-router-dom";
import Backgorund from "../views/General/Backgorund";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";

const theme = createMuiTheme({
  direction: "rtl",
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    margin: "0 auto",
    height: "100vh",
    overflow: "hidden",
    background: `url(${process.env.PUBLIC_URL}/cool-background.png) `,
  },
  demo: {
   // backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: "bold",
  },
  square: {
    margin: "0 auto",
  },
  gridContainer: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  box: {
    backgroundColor: theme.palette.background.paper,
    padding: "1rem",
    boxShadow: "0 1rem 2rem rgba(0, 0, 0, .2)",
    borderRadius: "50px",
    margin: "1rem 1rem",
    textAlign: "center",
  },
  avatar: {
    margin: ".5rem auto",
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function InteractiveList() {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <RTL>
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} md={6} className={classes.square}>
              <Typography variant="h6" className={classes.title}>
                اختار نوع شركتك
              </Typography>
              <div className={classes.demo}>
                <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Link
                      to={{ pathname: "/eventsLogin", state: { events: true } }}
                    >
                      <div className={classes.box}>
                        <Avatar className={classes.avatar}>
                          <MicIcon />
                        </Avatar>
                        <h1 style={{ margin: ".5rem",color:'#000' }}>احتفالات</h1>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Link
                     to={{
                      pathname: "/entertainemntLogin",
                      state: { entertainment: true },
                    }}
                    >
                      <div className={classes.box}>
                        <Avatar className={classes.avatar}>
                          <RowingIcon />
                        </Avatar>
                        <h1 style={{ margin: ".5rem",color:'#000' }}>ترفيه</h1>
                      </div>
                    </Link>
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={6}>
                    <Link
                        to={{ pathname: "/hotelsLogin", state: { hotels: true } }}
                    >
                      <div className={classes.box}>
                        <Avatar className={classes.avatar}>
                          <HotelIcon />
                        </Avatar>
                        <h1 style={{ margin: ".5rem",color:'#000' }}>فنادق</h1>
                      </div>
                    </Link>
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={6}>
                    <Link
                                        to={{ pathname: "/clinicsLogin", state: { clinics: true } }}

                    >
                      <div className={classes.box}>
                        <Avatar className={classes.avatar}>
                          <LocalHospitalIcon />
                        </Avatar>
                        <h1 style={{ margin: ".5rem",color:'#000' }}>عيادات</h1>
                      </div>
                    </Link>
                  </GridItem>
                  
                      </GridContainer>
                {/* <List dense={dense}>
                  <Link
                    to={{ pathname: "/eventsLogin", state: { events: true } }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Events"
                        secondary={secondary ? "Secondary text" : null}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                  <Link
                    to={{
                      pathname: "/entertainemntLogin",
                      state: { entertainment: true },
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="ترفيه"
                        secondary={secondary ? "Secondary text" : null}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                  <Link
                    to={{ pathname: "/hotelsLogin", state: { hotels: true } }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="فنادق"
                        secondary={secondary ? "Secondary text" : null}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                  <Link
                    to={{ pathname: "/clinicsLogin", state: { clinics: true } }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="عيادات"
                        secondary={secondary ? "Secondary text" : null}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                </List>
              */}
               </div>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    </RTL>
  );
}
