

import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import moment from "moment";

import Home from "./layouts/Home";
import EventsLogin from "./views/EventsViews/Login/EventsLogin";
import EntertainmentLogin from "./views/EntertainmentViews/Login/EntertainmentLogin";
import Entertainment from "./layouts/Entertainment";
import RedirectRoute from "./views/General/RedirectRoute";
import HotelsLogin from "./views/HotelsViews/Login/HotelsLogin";
import Hotels from "./layouts/Hotels";
import ClinicsLogin from "./views/ClinicsViews/Login/ClinicsLogin";
import Clinics from "./layouts/Clinics";
import 'chartist/dist/chartist.min.css'
import "assets/css/material-dashboard-react.css?v=1.8.0 ";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "components/auth/ProtectedRoute";
import LoggedInRoute from "components/auth/LoggedInRoute";

// core components
 
import Events from "layouts/Events.js";
import Logout from "layouts/Logout";

const store = require("./reducers").init();

const hist = createBrowserHistory();

ReactDOM.render(
 <Provider store={store}> 
    <Router history={hist}>
    <Switch>
      <ProtectedRoute path="/clinics" component={Clinics} />
      <ProtectedRoute path="/hotels" component={Hotels} />
      <ProtectedRoute path="/entertainment" component={Entertainment} />
      <ProtectedRoute path="/events" component={Events} />

      <LoggedInRoute path="/eventsLogin" component={EventsLogin} /> 
      <LoggedInRoute path="/entertainemntLogin" component={EntertainmentLogin} />
      <LoggedInRoute path="/HotelsLogin" component={HotelsLogin} />
      <LoggedInRoute path="/clinicsLogin" component={ClinicsLogin} />
      <Route path="/logout" component={Logout} /> 
      
      <LoggedInRoute path="/home" component={Home} /> 
      {/* <Route path="/redirect" component={RedirectRoute} />  */}

      <Redirect from="/" to="/home" />
    </Switch>
  </Router>
 </Provider>,
  document.getElementById("root") 
);
