import React from "react";
import moment from "moment";
import AddReservedDate from "../../General/AddReservedDate";
import ReservedDatesTable from "../../General/ReservedDatesTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Check, Clear } from "@material-ui/icons";
import AddClinicsReservedDate from "./AddClinicsReservedDate";

export default function EntertainmentReservedDates(props) {
  const [added, setAdded] = React.useState(0);
  const addedFun = () => {
    setAdded(added + 1);
  };
  const columns = [
    {
      title: "التاريخ",
      field: "reservedDates.date",
      render: (rowData) =>
        moment(rowData.reservedDates.date).format("YYYY/MM/DD"),
    },
    {
        title: "بداية الوقت",
        field: "reservedDates.startingTime",
        render: (rowData) =>
          rowData.reservedDates.full
            ? "00:00"
            : moment(rowData.reservedDates.startingTime, "HH:mm").format("HH:mm"),
      },
      {
        title: "نهاية الوقت",
        field: "reservedDates.endingTime",
        render: (rowData) =>
          rowData.reservedDates.full
            ? "00:00"
            : moment(rowData.reservedDates.endingTime, "HH:mm").format("HH:mm"),
      },
      {
        title: "اليوم كاملا",
        field: "reservedDates.full",
        
        lookup: { true: <Check />, false: <Clear /> },
      },
  ];
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={5}>
        <AddClinicsReservedDate
          addedFuncion={addedFun}
          type="clinics"
          section="clinicsProducts"
          id={props.match.params.id}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={7}>
        <ReservedDatesTable
        columns={columns}
          render={added}
          type="clinicsProducts"
          id={props.match.params.id}
        />
      </GridItem>
    </GridContainer>
  );
}
