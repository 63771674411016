import moment from "moment";

const validate = (selectedElement, formData) => {
  let error = [true, ""];
  if (selectedElement.validation.email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const valid = re.test(String(selectedElement.value).toLowerCase());
    const message = `${!valid ? "Email Not Correct" : ""}`;
    error = !valid ? [valid, message] : error;
  }
  if (selectedElement.validation.confirm) {
    const valid =
      selectedElement.value ===
      formData[selectedElement.validation.confirm].value;
    const message = `${!valid ? "Password not confirmed" : ""}`;
    error = !valid ? [valid, message] : error;
  }
  if (selectedElement.validation.required) {
    const valid = selectedElement.value !== "";

    const message = `${!valid ? "Feild Is required" : ""}`;
    error = !valid ? [valid, message] : error;
  }
  if (selectedElement.validation.ArrayRequired) {
    const valid = selectedElement.value.length > 0;

    const message = `${!valid ? "Feild Is required" : ""}`;
    error = !valid ? [valid, message] : error;
  }
  return error;
};
// the element consisits of event, id, and blur
export const update = (element, formData, formNmae) => {
  const newFormData = { ...formData };
  //this will select the data of id: email or password
   
  const selectedElement = { ...newFormData[element.id] };
  if (
    element.type &&
    (element.type === "file" || element.type === "checkbox")
  ) {
    selectedElement.value = element.event;
    if(selectedElement.config.name==='full_input'){
     if(selectedElement.value){
      newFormData['startingTime'].value= '00:00';
      newFormData['startingTime'].config.disabled= true;
      newFormData['endingTime'].value= '00:00';
      newFormData['endingTime'].config.disabled= true;
     }else{
    
      newFormData['startingTime'].config.disabled= false;

      newFormData['endingTime'].config.disabled= false;
     }

    }
  } else if (element.type === "time") {
    selectedElement.value =
      element.event !== null
        ? moment(element.event, "hh:mm").format("HH:mm").toString()
        : "";
  }
  else if (element.type === "date") {
    selectedElement.value =
      element.event !== null
        ? moment(element.event, "YMD").locale('en-ca').format("YYYY/MM/DD").toString()
        : "";
  } else {
    debugger
    selectedElement.value =
      selectedElement.config.type === "number"
        ? parseInt(element.event.target.value)
        : element.event.target.value;
  }

  if (element.blur) {
    const validData = validate(selectedElement, formData);
    selectedElement.valid = validData[0];
    selectedElement.validationMessage = validData[1];
  }
  if (element.id === "percentage") {
    const orboonElement = { ...newFormData["orboon"] };
    orboonElement.value =
      newFormData["price"].value * (parseInt(selectedElement.value) / 100) || 0;
    orboonElement.value =
      orboonElement.value > 1000
        ? Math.floor(Math.floor(orboonElement.value) / 1000) * 1000
        : Math.floor(Math.floor(orboonElement.value) / 100) * 100;
    newFormData["orboon"] = orboonElement;
  }
  if (element.id === "price") {
    const orboonElement = { ...newFormData["orboon"] };
    orboonElement.value =
      selectedElement.value *
        (parseInt(newFormData["percentage"].value) / 100) || 0;
    newFormData["orboon"] = orboonElement;
  }
  if (element.id === "secondStartingTime") {
    const endElement = { ...newFormData["secondEndTime"] };
    if (selectedElement.value !== null && selectedElement.value !== "") {
      endElement.config.required = true;
      endElement.config.disabled = false;
    } else {
      endElement.config.required = false;
      endElement.config.disabled = true;
    }
  }
  selectedElement.touched = element.blur;
  newFormData[element.id] = selectedElement;

  return newFormData;
};

export const generateData = (formData, formName) => {
  let dataToSubmit = {};

  for (let key in formData) {
    if (
      key !== "orboon" &&
      key !== "percentage" &&
      key !== "city" &&
      key !== "street" &&
      key !== "length" &&
      key !== "width" &&
      key !== "firstStartingTime" &&
      key !== "firstEndTime" &&
      key !== "secondStartingTime" &&
      key !== "secondEndTime"
    ) {
      dataToSubmit[key] = formData[key].value;
    } else if (key === "orboon") {
      dataToSubmit[key] = {
        percentage: formData["percentage"].value,
        priceToPay: formData[key].value,
      };
    } else if (key === "city") {
      dataToSubmit["address"] = {
        city: formData[key].value,
        street: formData["street"].value,
      };
    } else if (key === "width") {
      dataToSubmit["size"] = formData[key].value * formData["length"].value;
    } else if (key === "firstStartingTime") {
      dataToSubmit["firstPeriod"] = {
        startingTime: formData[key].value,
        endingTime: formData["firstEndTime"].value,
      };
    } else if (
      key === "secondStartingTime" &&
      formData[key].value !== "" &&
      formData["secondEndTime"].value !== ""
    ) {
      dataToSubmit["secondPeriod"] = {
        startingTime: formData[key].value,
        endingTime: formData["secondEndTime"].value,
      };
    }
  }
  return dataToSubmit;
};

export const isFormValid = (formData, formName) => {
  let formIsValid = true;

  for (let key in formData) {
    const validData = validate(formData[key], formData);
    formData[key].valid = validData[0];
    formData[key].touched = true;
    formData[key].validationMessage = validData[1];
    formIsValid = formData[key].valid && formIsValid; // checking the whole form
  }

  return formIsValid;
};
export const populateOptionFeilds = (formData, arrayData = [], feild) => {
  const newArray = [];
  const newFormData = { ...formData };
  arrayData.forEach((item) => {
    newArray.push({ key: item._id, value: item.name });
  });

  newFormData[feild].config.options = newArray;
  return newFormData;
};
export const reserFeildData = (fromData, type) => {
  const newFormData = { ...fromData };
  for (let key in newFormData) {
    newFormData[key].value = "";
    newFormData[key].valid = false;
    newFormData[key].touched = false;
    newFormData[key].validationMessage = "";
  }
  return newFormData;
};
