import React, { Component } from "react";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar } from "material-table";
import { withRouter, Link } from "react-router-dom";

import moment from "moment";
import { Check, Clear } from "@material-ui/icons";


class FinanceGeneralTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
      columns: [
        {
          title: "الرقم",
          field: "no",
        },
        {
          title: "المستخدم",
          field: "customer.name",

          render: (rowData) =>
            this.renderColumn({
              to: "/events",
              value: rowData.customer.name,
            }),
        },
        {
          title: "تاريخ البداية",
          field: "startAt",
          render: (rowData) => moment(rowData.startAt).format("YYYY/MM/DD"),
        },
        {
          title: "تاريخ النهاية",
          field: "endAt",
          render: (rowData) => moment(rowData.endAt).format("YYYY/MM/DD"),
        },
        { title: "الكمية", field: "quantity" },
        {
          title: " المنتج",
          field: "product.name",
          render: (rowData) =>
            this.renderColumn({
              to: {
                pathname: "/events/products",
                state: { searchValue: `${rowData.product.name}` },
              },
              value: rowData.product.name,
            }),
        },
        { title: "دفع", field: "paid" },
        {
          title: "باقي عنده",
          field: "totalPrice",
          render: (rowData) => rowData.totalPrice - rowData.paid,
        },
        {
          title: "تم الدفع",
          field: "fullyPaid",
          lookup: { true: <Check />, false: <Clear /> },
        },
        {
          title: "تم الاستلام",
          field: "delivered",
          lookup: { true: <Check />, false: <Clear /> },
        },
        {
          title: "تم الارجاع",
          field: "returned",
          render: (rowData) => (rowData.returned ? <Check /> : <Clear />),
        },
      ],
      financeColumns: [
        {
          title: "الرقم",
          field: "no",
        },
        { title: "الميلغ", field: "payment" },
        {
          title: "تاريخ السند",
          field: "date",
          render: (rowData) => moment(rowData.createdAt).format("YYYY/MM/DD"),
        },
        {
          title: "اسم المستلم",
          field: "takerName",
        },
        {
          title: "اسم المعطي",
          field: "giverName",
        },
      ],
      totalPrice: 0,
    };
  }

  componentWillReceiveProps(prevProps) {
    if (
      prevProps.filterData.filters.startDate !== this.props.filterData.filters.startDate ||
      prevProps.filterData.filters.endDate !== this.props.filterData.filters.endDate||
      prevProps.filterData.filters.String !== this.props.filterData.filters.String
    ) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }
  renderColumn = (rowData) => (
    <Link className="link" to={rowData.to}>
      {rowData.value}
    </Link>
  );
  render() {
    return (
      <MaterialTable
        title={this.props.title}
        tableRef={this.tableRef}
        columns={this.props.columns}
        data={(query) =>
          new Promise((resolve, reject) => {
             
            this.props.getList(
              query.page,
              query.pageSize,
              query.orderBy,
              query.orderDirection,
              this.props.type,
              this.props.id ?this.props.id :this.props.filterData
            )
              .then((res) => {
                console.log(res);
                this.props.setStates(res)
                resolve(res);
              })
              .catch((err) => {
                 
                alert("err");
              });
          })
        }
        components={{
          Toolbar:  this.props.renderToolbar
        }}
        detailPanel={[
          {
            tooltip: "مزيد",
            render: this.props.renderDetailPanel
          },
        ]}
      />
    );
  }
}

export default withRouter(FinanceGeneralTable);
