import React, { Component } from "react";
import { connect } from "react-redux";
import Material_Table, { MTableToolbar } from "material-table";
import { withRouter, Link } from "react-router-dom";
import { getBookings, editBooking, getReceipts } from "actions";
import moment from "moment";
import { Check, Clear } from "@material-ui/icons";
import { Chip } from "@material-ui/core";
import Info from "../../../components/Typography/Info";
import FinanceGeneralTable from "../../../components/Finance/FinanceGeneralTable";

/////////////////////////////////////////////////////////
class FinanceReceiptsTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
    
      financeColumns: [
        {
          title: "الرقم",
          field: "no",
        },
        { title: "الميلغ", field: "payment" },
        {
          title: "تاريخ السند",
          field: "date",
          render: (rowData) => moment(rowData.createdAt).format("YYYY/MM/DD"),
        },
        {
          title: "اسم المستلم",
          field: "takerName",
        },
        {
          title: "اسم المعطي",
          field: "giverName",
        },
      ],
      totalPrice: 0,
    };
  }

  
  renderToolbar = (props) => (
    <div>
      {console.log(props)}

      <MTableToolbar {...props} />
      <div
        style={{
          padding: "0px 10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h5 style={{ margin: "0" }}>المجموع</h5>
        <Chip
          label={this.state.totalPrice}
          color="secondary"
          style={{ marginRight: 12 }}
        />
      </div>
    </div>
  );
  renderDetailPanel = (rowData) => {
    return (
      <div
        style={{
          fontSize: 10,
          textAlign: "right",
          color: "white",
          backgroundColor: "#43A047",
        }}
      >
        {rowData.info}
      </div>
    );
  };
  setStates = (res) => {
    this.setState({ totalPrice: res.totalPrice });
  };
  render() {
    return (
      <FinanceGeneralTable
        filterData={this.props.filterData}
        title="قائمة سندات الصرف"
        columns={this.state.financeColumns}
        getList={getReceipts}
        renderToolbar={this.renderToolbar}
        renderDetailPanel={this.renderDetailPanel}
        setStates={this.setStates}
        type="Clinics"
      />
    );
  }
}


export default withRouter(FinanceReceiptsTable);
