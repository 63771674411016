import React from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { EVENTS, ENTERTAINMENT, HOTELS, CLINIC } from '../../services/helpers';

 function RedirectRoute(props) {
    return (
        <>
   { props.globalProps && props.globalProps.layout? ( 
        <>
         {props.globalProps.layout ===EVENTS &&  <Redirect to="events"/>}
       {props.globalProps.layout ===ENTERTAINMENT &&  <Redirect to="entertainment"/>}
    
  {props.globalProps.layout ===HOTELS &&  <Redirect to="hotel"/>}
   
   {props.globalProps.layout ===CLINIC &&  <Redirect to="clinic"/>}
        </>
)   : <Redirect to="logout"/>}  
       </>
    )
}



const mapStateToProps = (state) => ({
    globalProps:state.globalProps
  });
  
  const mapDispatchToProps = {
   
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(RedirectRoute);
  