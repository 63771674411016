import React, { Component } from 'react'
// @material-ui/icons

import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import { bugs, website, server } from "variables/general.js";
import EventsMaterialTable from '../EventsMaterial-Table/EventsMaterialTable';
import FinanceReceiptsTable from './FinanceReceiptsTable';
import FinanceIncomeTable from './FinanceIncomeTable';


export default class FinanceTablesTabs extends Component {

    setTotalPrice = res=>{
        console.log(res);
        
    }
    render() {
        return (
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
              <CustomTabs
                title="الأموال"
                headerColor="warning"
                tabs={[
                  {
                    tabName: "الحجوزات",
                    tabIcon: BugReport,
                    tabContent: (
                        <FinanceIncomeTable filterData={{
                            filters: {
                                startDate:this.props.date.createdAtStart,
                                endDate:this.props.date.createdAtEnd,
                              string: "",
                            },
                          }} />
                        // <EventsMaterialTable setTotalPrice={this.setTotalPrice} finance={true} date={this.props.date}/>
                    )
                  },
                  {
                    tabName: "سندات الصرف",
                    tabIcon: Code,
                    tabContent: (
                    <FinanceReceiptsTable filterData={{
                        filters: {
                            startDate:this.props.date.createdAtStart,
                            endDate:this.props.date.createdAtEnd,
                          string: "",
                        },
                      }} />
                    )
                  },
                  
                ]}
              />
            </GridItem>
                 </GridContainer>
       
        )
    }
}
