import React from "react";

import moment from "moment";
import TableWrapper from "../../../components/TableWrapper/TableWrapper";
import FinanceTablesTabs from "./FinanceTablesTabs";
import "moment/locale/en-ca";

export default function FinanceHotelsPage() {
  moment.locale("en-ca");

  const [startDate, handleStartDateChange] = React.useState(moment("2020-01-01"));
  const [endDate, handleEndDateChange] = React.useState(moment());

  return (
    <TableWrapper
      startDate={startDate}
      endDate={endDate}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
    >
      <FinanceTablesTabs
        date={{
          createdAtStart: startDate.locale("en-ca").format("YYYY/MM/DD"),
          createdAtEnd: endDate.locale("en-ca").format("YYYY/MM/DD"),
        }}
      />
    </TableWrapper>
  );
}
