import React from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import FormFeild from "components/Form/FormFeild";
import { addReservedDate } from "actions";
import { connect } from "react-redux";
import Snackbar from "components/Snackbar/Snackbar";

import Form from "../../Form/Form";

class AddClinicsReservedDate extends Form {
  constructor(props) {
    super(props);
    this.state = {
      formPending: false,
      section: "",
      sectionArabic: "",
      create: true,
      redirect: false,
      formError: false,
      formErrorMessage: "",
      formSuccess: "",
      formData: {
       
      date: {
        element: "date",
        value: "",
        config: {
          name: "date_input",
          type: "date",
        },
        validation: {
          required: true,
        },
        valid: null,
        touched: false,
        validationMessage: "",
        label: " تاريخ ",
      },
      full: {
        element: "checkbox",
        value: false,
        config: {
          name: "full_input",
          type: "checkbox",
        },
        validation: {
          
        },
        valid: null,
        touched: false,
        validationMessage: "",
        label: "البوم كاملا ",
      },
      startingTime: {
        element: "time",
        value: "00:00",
        config: {
          name: "startingTime_input",
          type: "time",
        },
        validation: {
          required: true,
          disabled:false

        },
        valid: null,
        touched: false,
        validationMessage: "",
        label: "بداية الوقت  ",
      },
      endingTime: {
        element: "time",
        value: "00:00",
        config: {
          name: "endingTime_input",
          type: "time",
        },
        validation: {
          required: true,
          disabled:false

        },
        valid: null,
        touched: false,
        validationMessage: "",
        label: "نهاية الفترة الأولى ",
      },
   
  },
      notificationError: true,
    };
  }
  emptyErrors = () => {};
  submitAction = (data) => {
    ///this.props.loginAction(data);

    console.log(data);
   
    this.setState({ formPending: true });

      addReservedDate(this.props.section,this.props.id, { ...data }).then(
        (created) => {
          const nwData = this.state.formData
          const feilds = Object.keys(nwData);

         feilds.forEach((feild, i) => {
           nwData[feild].value= ''
         });
         this.props.addedFuncion()
         this.setState({
          formError: false,
          formPending: false,
          formData:nwData
          
        });
        },
        (errors) => {
          this.setState({
            formError: true,
            formPending: false,
            notificationError: true,
            formErrorMessage: errors[0].message,
          });
        }
      );
    
  };
  showNotification = (message, color) => {
    // setTimeout(() => {
    //   this.setState({ notificationError: false });
    // }, 3000);

    return (
      <Snackbar
        place="tl"
        color={color}
        message={`${message}`}
        open={this.state.notificationError}
        closeNotification={() => this.setState({ notificationError: false })}
        close
      />
    );
  };
  renderFormFeilds = () => {
    const feilds = Object.keys(this.state.formData);

    return feilds.map((feild, i) => (
      <GridContainer key={i}>
        <GridItem xs={12} sm={12} md={12}>
          {(!this.state.formData[feild].type || this.state.formData[feild].type === this.props.type) && (
            <FormFeild
              id={feild}
              formData={this.state.formData[feild]}
              change={(element) => this.updateForm(element)}
              isError={this.state.formError}
            />
          )}
        </GridItem>
      </GridContainer>
    ));
  };
  render() {
    const {
      create,
      formError,
      formErrorMessage,
      
      formPending,
    } = this.state;
 
    return (
      <>
      
        {formError && this.showNotification(formErrorMessage, "danger")}
       
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className="cardTitleWhite">
                  اضافة تواريخ محجوزة
                </h4>
                <p className="cardCategoryWhite">ادخل تواريخ ترغب بحجزها</p>
              </CardHeader>
              <CardBody>{this.renderFormFeilds()}</CardBody>
              <CardFooter>
                <Button
                  disabled={formPending}
                  onClick={this.submitForm}
                  color="primary"
                >
                  {formPending ? "تحميل..."  
                  :  "إضافة " }
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          
      
      </>
    );
  }
}

export default AddClinicsReservedDate;
