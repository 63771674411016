import React, { Component } from "react";
import { connect } from "react-redux";
import Material_Table, { MTableToolbar } from "material-table";
import { withRouter, Link } from "react-router-dom";
import { getBookings, editBooking, getReceipts } from "actions";
import moment from "moment";
import { Check, Clear } from "@material-ui/icons";
import { Chip } from "@material-ui/core";
import Info from "../../../components/Typography/Info";
import FinanceGeneralTable from "../../../components/Finance/FinanceGeneralTable";

/////////////////////////////////////////////////////////
class FinanceReceiptsTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
    
      financeColumns: [
        {
          title: "الرقم",
          field: "no",
        },
        { title: "الميلغ", field: "payment" },
        {
          title: "تاريخ السند",
          field: "date",
          render: (rowData) => moment(rowData.createdAt).format("YYYY/MM/DD"),
        },
        {
          title: "اسم المستلم",
          field: "takerName",
        },
        {
          title: "اسم المعطي",
          field: "giverName",
        },
      ],
      totalPrice: 0,
    };
  }

  
  renderToolbar = (props) => (
    <div>
      {console.log(props)}

      <MTableToolbar {...props} />
      <div
        style={{
          padding: "0px 10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h5 style={{ margin: "0" }}>المجموع</h5>
        <Chip
          label={this.state.totalPrice}
          color="secondary"
          style={{ marginRight: 12 }}
        />
      </div>
    </div>
  );
  renderDetailPanel = (rowData) => {
    return (
      <div
        style={{
          fontSize: 10,
          textAlign: "right",
          color: "white",
          backgroundColor: "#43A047",
        }}
      >
        {rowData.info}
      </div>
    );
  };
  setStates = (res) => {
    this.setState({ totalPrice: res.totalPrice });
  };
  render() {
    return (
      <FinanceGeneralTable
        filterData={this.props.filterData}
        title="قائمة سندات الصرف"
        columns={this.state.financeColumns}
        getList={getReceipts}
        renderToolbar={this.renderToolbar}
        renderDetailPanel={this.renderDetailPanel}
        setStates={this.setStates}
        type="Entertainment"
      />
    );
  }
}
// class FinanceReceiptsTable extends React.Component {
//   constructor(props) {
//     super(props);

//     this.tableRef = React.createRef();
//     this.state = {
//       columns: [
//         {
//           title: "الرقم",
//           field: "no",
//         },
//         {
//           title: "المستخدم",
//           field: "customer.name",

//           render: (rowData) =>
//             this.renderColumn({
//               to: "/events",
//               value: rowData.customer.name,
//             }),
//         },
//         {
//           title: "تاريخ البداية",
//           field: "startAt",
//           render: (rowData) => moment(rowData.startAt).format("YYYY/MM/DD"),
//         },
//         {
//           title: "تاريخ النهاية",
//           field: "endAt",
//           render: (rowData) => moment(rowData.endAt).format("YYYY/MM/DD"),
//         },
//         { title: "الكمية", field: "quantity" },
//         {
//           title: " المنتج",
//           field: "product.name",
//           render: (rowData) =>
//             this.renderColumn({
//               to: {
//                 pathname: "/events/products",
//                 state: { searchValue: `${rowData.product.name}` },
//               },
//               value: rowData.product.name,
//             }),
//         },
//         { title: "دفع", field: "paid" },
//         {
//           title: "باقي عنده",
//           field: "totalPrice",
//           render: (rowData) => rowData.totalPrice - rowData.paid,
//         },
//         {
//           title: "تم الدفع",
//           field: "fullyPaid",
//           lookup: { true: <Check />, false: <Clear /> },
//         },
//         {
//           title: "تم الاستلام",
//           field: "delivered",
//           lookup: { true: <Check />, false: <Clear /> },
//         },
//         {
//           title: "تم الارجاع",
//           field: "returned",
//           render: (rowData) => (rowData.returned ? <Check /> : <Clear />),
//         },
//       ],
//       financeColumns: [
//         {
//           title: "الرقم",
//           field: "no",
//         },
//         { title: "الميلغ", field: "payment" },
//         {
//           title: "تاريخ السند",
//           field: "date",
//           render: (rowData) => moment(rowData.createdAt).format("YYYY/MM/DD"),
//         },
//         {
//           title: "اسم المستلم",
//           field: "takerName",
//         },
//         {
//           title: "اسم المعطي",
//           field: "giverName",
//         },
//       ],
//       totalPrice: 0,
//     };
//   }

//   componentWillReceiveProps(prevProps) {
//     if (
//       prevProps.filterData.filters.startDate !== this.props.filterData.filters.startDate ||
//       prevProps.filterData.filters.endDate !== this.props.filterData.filters.endDate||
//       prevProps.filterData.filters.String !== this.props.filterData.filters.String
//     ) {
//       this.tableRef.current && this.tableRef.current.onQueryChange();
//     }
//   }
//   renderColumn = (rowData) => (
//     <Link className="link" to={rowData.to}>
//       {rowData.value}
//     </Link>
//   );
//   render() {
//     return (
//       <Material_Table
//         title="قائمة سندات الصرف"
//         tableRef={this.tableRef}
//         columns={this.state.financeColumns}
//         data={(query) =>
//           new Promise((resolve, reject) => {
//              
//             getReceipts(
//               query.page,
//               query.pageSize,
//               query.orderBy,
//               query.orderDirection,
//               "Events",
//               this.props.filterData
//             )
//               .then((res) => {
//                 console.log(res);
//                 this.setState({ totalPrice: res.totalPrice });
//                 resolve(res);
//               })
//               .catch((err) => {
//                  
//                 alert("err");
//               });
//           })
//         }
//         components={{
//           Toolbar: (props) => (
//             <div>
//               {console.log(props)}

//               <MTableToolbar {...props} />
//               <div
//                 style={{
//                   padding: "0px 10px",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <h5 style={{ margin: "0" }}>المجموع</h5>
//                 <Chip
//                   label={this.state.totalPrice}
//                   color="secondary"
//                   style={{ marginRight: 12 }}
//                 />
//               </div>
//             </div>
//           ),
//         }}
//         detailPanel={[
//           {
//             tooltip: "مزيد",
//             render: (rowData) => {
//               return (
//                 <div
//                   style={{
//                     fontSize: 10,
//                     textAlign: "right",
//                     color: "white",
//                     backgroundColor: "#43A047",
//                   }}
//                 >
//                   {rowData.info}
//                 </div>
//               );
//             },
//           },
//         ]}
//       />
//     );
//   }
// }

export default withRouter(FinanceReceiptsTable);
