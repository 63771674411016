import ViewEventsProducts from './views/EventsViews/Products/ViewEventsProducts'

import CreateProduct from './views/EventsViews/Products/CreateProduct';



import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Money from "@material-ui/icons/Money";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";

//import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import EventsDashboard from "views/EventsViews/EventsDashboard";
import  ViewProducts  from "views/Products/ViewProducts";
import EventsTableList from './views/EventsViews/EventsMaterial-Table/EventsTableList';
import EntertainmentDashboard from './views/EntertainmentViews/EntertainmentDashboard';
import EntertainmentCreateProduct from './views/EntertainmentViews/Products/EntertainmentCreateProduct';
import ViewEntertainmentProducts from './views/EntertainmentViews/Products/ViewEntertainmentProducts';
import EntertainmentTableList from 'views/EntertainmentViews/EntertainmentMaterial-Table/EntertainmentTableList';
import HotelsDashboard from './views/HotelsViews/HotelsDashboard';
import ViewHotelsProducts from './views/HotelsViews/Products/ViewHotelsProducts';
import HotelsCreateProduct from './views/HotelsViews/Products/HotelsCreateProduct';
import HotelsTableList from './views/HotelsViews/HotelsMaterial-Table/HotelsTableList';
import ClinicsDashboard from './views/ClinicsViews/ClinicsDashboard';
import ViewClinicsProducts from './views/ClinicsViews/Products/ViewClinicsProducts';
import ClinicsCreateProduct from './views/ClinicsViews/Products/ClinicsCreateProduct';
import ClinicsTableList from './views/ClinicsViews/ClinicsMaterial-Table/ClinicsTableList';
import FinanceEventsPage from './views/EventsViews/EventsFinance/FinanceEventsPage';
import FinanceEntertainmentPage from './views/EntertainmentViews/EntertainementFinance/FinanceEntertainmentPage';
import FinanceHotelsPage from './views/HotelsViews/HotelsFinance/FinanceHotelsPage';
import FinanceClinicsPage from './views/ClinicsViews/ClinicsFinance/FinanceClinicsPage';
import Dashboard2 from './views/Dashboard/Dashboard';
import EventsReservedDates from './views/EventsViews/Products/EventsReservedDates';
import EntertainmentReservedDates from './views/EntertainmentViews/Products/EntertainmentReservedDates';
import HotelsReservedDates from './views/HotelsViews/Products/HotelsReservedDates';
import ClinicsReservedDates from './views/ClinicsViews/Products/ClinicsReservedDates';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/rtl"
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/rtl"
  },
  {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/rtl"
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/rtl"
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/rtl"
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Icons,
    layout: "/rtl"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/rtl"
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/rtl"
  }
];
const EventsRoute = [
  {
    path: "/dashboard",
    name: "لوحة التحكم",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: EventsDashboard,
    layout: "/events"
  },
  {
    path: "/products/reservedDates/:id",
 
    sub:true,
    component: EventsReservedDates,
    layout: "/events"
  },
  {
    path: "/products/:id",
 
    sub:true,
    component: CreateProduct,
    layout: "/events"
  },
  {
    path: "/products",
    name: "المنتجات",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ViewEventsProducts,
    layout: "/events"
  },
  {
    path: "/bookings",
    name: "الحجوزات",
    rtlName: "الحجوزات ",
    icon: "content_paste",
    component: EventsTableList,
    layout: "/events"
  },
  {
    path: "/finance",
    name: "الأرباح",
    rtlName: "الأرباح ",
    icon: Money,
    component: FinanceEventsPage,
    layout: "/events"
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/events"
  // },
 
 

  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/events"
  // }
];
const EntertainmentRoute = [
  {
    path: "/dashboard",
    name: "لوحة التحكم",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: EntertainmentDashboard,
    layout: "/entertainment"
  },
  {
    path: "/products/reservedDates/:id",
 
    sub:true,
    component: EntertainmentReservedDates,
    layout: "/entertainment"
  },
  {
    path: "/products/:id",
 
    sub:true,
    component: EntertainmentCreateProduct,
    layout: "/entertainment"
  },
  {
    path: "/products",
    name: "المنتجات",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ViewEntertainmentProducts,
    layout: "/entertainment"
  },
  {
    path: "/bookings",
    name: "الحجوزات",
    rtlName: "الحجوزات ",
    icon: "content_paste",
    component: EntertainmentTableList,
    layout: "/entertainment"
  },
  {
    path: "/finance",
    name: "الأرباح",
    rtlName: "الأرباح ",
    icon: Money,
    component: FinanceEntertainmentPage,
    layout: "/entertainment"
  },
 
];
const HotelsRoute = [
  {
    path: "/dashboard",
    name: "لوحة التحكم",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: HotelsDashboard,
    layout: "/hotels"
  },
  {
    path: "/products/reservedDates/:id",
 
    sub:true,
    component: HotelsReservedDates,
    layout: "/hotels"
  },
  {
    path: "/products/:id",
 
    sub:true,
    component: HotelsCreateProduct,
    layout: "/hotels"
  },
  {
    path: "/products",
    name: "المنتجات",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ViewHotelsProducts,
    layout: "/hotels"
  },
  {
    path: "/bookings",
    name: "الحجوزات",
    rtlName: "الحجوزات ",
    icon: "content_paste",
    component: HotelsTableList,
    layout: "/hotels"
  },
  {
    path: "/finance",
    name: "الأرباح",
    rtlName: "الأرباح ",
    icon: Money,
    component: FinanceHotelsPage,
    layout: "/hotels"
  },
 
];
const ClinicsRoute = [
  {
    path: "/dashboard",
    name: "لوحة التحكم",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ClinicsDashboard,
    layout: "/clinics"
  },
  {
    path: "/products/reservedDates/:id",
 
    sub:true,
    component: ClinicsReservedDates,
    layout: "/clinics"
  },
  {
    path: "/products/:id",
 
    sub:true,
    component: ClinicsCreateProduct,
    layout: "/clinics"
  },
  {
    path: "/products",
    name: "المنتجات",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ViewClinicsProducts,
    layout: "/clinics"
  },
  {
    path: "/bookings",
    name: "الحجوزات",
    rtlName: "الحجوزات ",
    icon: "content_paste",
    component: ClinicsTableList,
    layout: "/clinics"
  },
  {
    path: "/finance",
    name: "الأرباح",
    rtlName: "الأرباح ",
    icon: Money,
    component: FinanceClinicsPage,
    layout: "/clinics"
  },
 
];

export default {EventsRoute,dashboardRoutes,EntertainmentRoute,HotelsRoute,ClinicsRoute};
