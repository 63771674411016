import React from "react";
import { connect } from "react-redux";

// @material-ui/core


import Dashboard from "../Dashboard/Dashboard";

function ClinicsDashboard({auth}) {
  return (
     auth.isAuth?
     <Dashboard type="Clinics" routeType='bookClinic'/>
  
     :null
 );
}


const mapStateToProps = (state) => ({
    auth:state.auth
});

const mapDispatchToProps = {
 
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicsDashboard);
