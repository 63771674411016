import {

  container
} from "assets/jss/material-dashboard-react.js";

const appStyle = theme => ({
  wrapper:{
    position: 'relative',
    width:'100%'

   },
   form:{
   position:'absolute',
  
   top:'50%',
   
   },
  container,
 
});

export default appStyle;
