import React from "react";
import { Route, Redirect } from "react-router-dom";
import authService from "services/auth-service";
export default props => {
  const { component: Component, ...rest } = props;
 
  return (
    <Route
      {...rest}
      render={props =>
        authService.isAuthenticated() ? (
         <>
          {authService.isEvents() ?
          <Redirect to={{ pathname: "/events"}} />
          :authService.isEntertainment() ?
          <Redirect to={{ pathname: "/entertainment"}} />
         : authService.isHotels() ?
          <Redirect to={{ pathname: "/hotels"}} />
         : authService.isClinics() ?
         <Redirect to={{ pathname: "/clinics"}} />:
         <Redirect to={{ pathname: "/logout"}} />}
         </>
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  );
};
