import React from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import FormFeild from "components/Form/FormFeild";
import Form from "views/Form/Form";
import { getCategories, getProductByID } from "../../../actions";
import { connect } from "react-redux";
import Snackbar from "components/Snackbar/Snackbar";
import { creatProduct, updateProduct } from "../../../actions";
import { Redirect } from "react-router-dom";

const imgsArray = [
  "https://miro.medium.com/max/1200/1*eJcRxwHj47_PXZyy_XajzQ.jpeg",
  "https://miro.medium.com/max/1072/0*BcBOnp05WwXKtavX.png",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ2jf4A_5q5hpSM7eE5lnhjpg9c00eMLXBBa3BEWFl2dP4itn96&usqp=CAU",
];
class CreateProduct extends Form {
  constructor() {
    super();
    this.state = {
      formPending: false,
      create: true,
      redirect: false,
      formError: false,
      formErrorMessage: "",
      formSuccess: "",
      formData: {
        photos: {
          element: "file",
          value: [],
          config: {
            name: "photos_input",
            type: "file",
            companyType: "hotels",
          },
          validation: {
            ArrayRequired: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: " صور",
        },
        name: {
          element: "input",
          value: "",
          config: {
            name: "name_input",
            type: "name",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "اسم المنتج",
        },
        description: {
          element: "input",
          value: "",
          config: {
            name: "description_input",
            type: "text",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "الوصف",
        },

        categoryName: {
          element: "select",
          value: "",
          config: {
            label: "اختار الصنف",
            name: "categoryName_input",
            options: [],
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          showLabel: true,
        },
        room_Num: {
          element: "input",
          value: "",
          config: {
            name: "room_Num_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "كم رقم الغرفة ",
        },
        beds: {
          element: "input",
          value: "",
          config: {
            name: "beds_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "عدد الأسرّة ",
        },
        adults: {
          element: "input",
          value: "",
          config: {
            name: "adults_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "البالغين ",
        },
        children: {
          element: "input",
          value: "",
          config: {
            name: "children_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "الأطفال ",
        },
        price: {
          element: "input",
          value: "",
          config: {
            name: "price_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "السعر لليوم",
        },
      
        percentage: {
          element: "select",
          value: "",
          config: {
            label: "نسبة العربون ",
            name: "percentage_input",
            // disabled:true,
            options: [
              { _id: 25, name: "%25" },
              { _id: 30, name: "%30" },
              { _id: 40, name: "%40 " },
              { _id: 50, name: "%50 " },
              { _id: 60, name: "%60 " },
              { _id: 70, name: "%70 " },
              { _id: 80, name: "%80 " },
              { _id: 90, name: "%90 " },
              { _id: 100, name: "%100" },
            ],
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          showLabel: true,
        },
        orboon: {
          element: "input",
          value: "",
          config: {
            name: "orboon_input",
            type: "number",
            disabled: true,
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: " العربون",
        },
        length: {
          element: "input",
          value: "",
          config: {
            name: "room_num_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "الطول بالمتر",
        },
        width: {
          element: "input",
          value: "",
          config: {
            name: "room_num_input",
            type: "number",
          },
          validation: {
            required: true,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "العرض بالمتر",
        },
        assets: {
          element: "input",
          value: "",
          config: {
            name: "assets_input",
            type: "text",
          },
          validation: {
            required: false,
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "مميزات المنتج (افصل بين كل كلم ب-) ",
        },
        wifi: {
          element: "checkbox",
          value: false,
          config: {
            name: "fan_input",
            type: "checkbox",
          },
          validation: {
            
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "الواي فاي ",
        },
        air: {
          element: "checkbox",
          value: false,
          config: {
            name: "fan_input",
            type: "checkbox",
          },
          validation: {
            
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: " مكيف ",
        },
        fan: {
          element: "checkbox",
          value: false,
          config: {
            name: "fan_input",
            type: "checkbox",
          },
          validation: {
            
          },
          valid: null,
          touched: false,
          validationMessage: "",
          label: "مروحة  ",
        },
      
      },
      
      notificationError: true,
    };
  }
  componentDidMount() {
    this._isMounted = true;

    this.props
      .getCategories("Hotels")
      .then((res) => {
        const nwFormData = { ...this.state.formData };
        nwFormData.categoryName.config.options = res;
        this._isMounted &&
          this.setState({
            formData: nwFormData,
          });
      })
      .catch((err) => {
        this.setState({
          formError: true,
          formErrorMessage: err ? err[0].message : "خطأ في جلب خيارات الأصناف",
        });
      });
    const id = this.props.match.params.id;
    if (id === "new") {
      return;
    }
    this.props
      .getProductByID(id, "hotelsProducts")
      .then((res) => {
        const nwFormData = { ...this.state.formData };
        Object.keys(nwFormData).forEach((key, i) => {
          if (
            key !== "orboon" &&
            key !== "percentage" &&
            key !== "categoryName" &&
            key !== "city" &&
            key !== "street"&&
            key !== "length"&&
            key !== "width"
          ) {
            nwFormData[key].value = res[key];
          } else if (key === "orboon") {
            nwFormData[key].value =
              res["price"] * (parseInt(res[key].percentage) / 100);
          } else if (key === "percentage") {
            nwFormData[key].value = res["orboon"].percentage;
          } else if (key === "categoryName") {
            nwFormData[key].value = res["categoryName"]._id;
          } else if (key === "city") {
            nwFormData[key].value = res["address"].city;
            nwFormData["street"].value = res["address"].street;
          }
          else if (key === "width") {
            nwFormData[key].value = res["size"]/2;
            nwFormData["length"].value = res["size"]/2;
            
          }
        });
        this._isMounted &&
          this.setState({
            formData: nwFormData,
            create: false,
          });
      })
      .catch((err) => {
        this.setState({
          create: false,
          formError: true,
          formErrorMessage: err[0]
            ? err[0].message
            : "خطأ    في جلب تفاصيل المنتج",
        });
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  emptyErrors = () => {
    //when input change
    const { errors } = this.props;
    if (errors && errors.length > 0) {
      this.props.resetErrors();
    }
  };
  submitAction = (data) => {
    ///this.props.loginAction(data);
    console.log(data);
    const { assets } = { ...data };
    console.log(assets);

    if (!(assets.constructor === Array)) {
       
      const assetsArray = assets.split(/[\-,]+/);
      data.assets = assetsArray;
    }
    const { create } = this.state;
    this.setState({ formPending: true });
    if (create) {
      creatProduct({ ...data }, "hotelsProducts").then(
        (created) => {
          this.setState({ redirect: true });
        },
        (errors) => {
          this.setState({
            formError: true,
            notificationError: true,
            formErrorMessage: errors[0].message,
          });
        }
      );
    } else {
      console.log("edited");
       
      updateProduct("hotelsProducts", this.props.match.params.id, {
        ...data,
      }).then(
        (updated) => {
          this.setState({ formPending: false, redirect: true });
        },
        (errors) => {
          this.setState({
            formError: true,
            notificationError: true,
            formPending: false,
            formErrorMessage: errors[0] ? errors[0].message : "خطأ غير معروف",
          });
        }
      );
    }
  };
  showNotification = (message, color) => {
    // setTimeout(() => {
    //   this.setState({ notificationError: false });
    // }, 3000);

    return (
      <Snackbar
        place="tl"
        color={color}
        message={`${message}`}
        open={this.state.notificationError}
        closeNotification={() => this.setState({ notificationError: false })}
        close
      />
    );
  };
  renderFormFeilds = () => {
    const feilds = Object.keys(this.state.formData);

    return feilds.map((feild, i) => (
      <GridContainer key={i}>
        <GridItem xs={12} sm={12} md={12}>
          <FormFeild
            id={feild}
            formData={this.state.formData[feild]}
            change={(element) => this.updateForm(element)}
            isError={this.state.formError}
          />
        </GridItem>
      </GridContainer>
    ));
  };
  render() {
    const { errors, productDetail, isLoading } = this.props.productDetail;
    const {
      create,
      formError,
      formErrorMessage,
      redirect,
      formPending,
    } = this.state;
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/hotels/products",
            state: { status: create ? "create" : "edit" },
          }}
        />
      );
    }
    return (
      <>
        {/* {errors &&
          errors.length > 0 &&
          this.showNotification(errors[0].message, "danger")} */}
        {formError && this.showNotification(formErrorMessage, "danger")}
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className="cardTitleWhite">
                  {create ? "إضافة منتج" : "تعديل المنتج"}
                </h4>
                <p className="cardCategoryWhite">Complete your profile</p>
              </CardHeader>
              <CardBody>{this.renderFormFeilds()}</CardBody>
              <CardFooter>
                <Button
                  disabled={formPending}
                  onClick={this.submitForm}
                  color="primary"
                >
                  {formPending ? "تحميل..." : create ? "إضافة " : "تعديل "}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img src={avatar} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6>CEO / CO-FOUNDER</h6>
                <h4>Alec Thompson</h4>
                <p>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </p>
                <Button color="primary" round>
                  Follow
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  productDetail: state.productDetail,
});

const mapDispatchToProps = {
  getCategories,
  getProductByID,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
