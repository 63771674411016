import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";

import {  cancellReservedDate, getReservedDates } from "actions";
import moment from "moment";

import FinanceGeneralTable from "../../components/Finance/FinanceGeneralTable";

/////////////////////////////////////////////////////////
class ReservedDatesTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
    
      columns: [
        {
          title: "البداية",
          field: "reservedDates.startAt",
          render: (rowData) => moment(rowData.reservedDates.startAt).format("YYYY/MM/DD"),

        },
        { title: "النهاية", field: "reservedDates.endAt",
        render: (rowData) => moment(rowData.reservedDates.endAt).format("YYYY/MM/DD"),
      },
        {
          title: "الكمية",
          field: "reservedDates.quantity",
        },
       
      ],
      totalPrice: 0,
    };
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.render !== this.props.render 
    ) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }
  
  renderToolbar = (props) => (
    <div>
     
    </div>
  );
  renderDetailPanel = (rowData) => {
    return (
     null
    );
  };
  setStates = (res) => {
    this.setState({ totalPrice: res.totalPrice });
  };
  render() {
    return (
      <MaterialTable
      title="قائمة سندات الصرف"

      tableRef={this.tableRef}
      columns={this.props.columns}
      data={(query) =>
        new Promise((resolve, reject) => {
           
          getReservedDates(
            query.page,
            query.pageSize,
            query.orderBy,
            query.orderDirection,
            this.props.type,
            this.props.id 
          )
            .then((res) => {
              console.log(res);
             
              resolve(res);
            })
            .catch((err) => {
               
              alert("err");
            });
        })
      }
      options={{
    
        rowStyle: (rowData) =>
          rowData.reservedDates.cancelled ? { backgroundColor: "#eee" } : null,

      }}
      
      editable={{
       
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            
      
            cancellReservedDate({_id:oldData.reservedDates._id}, this.props.id,this.props.type)
              .then((res) => resolve())
              .catch((err) => alert("eee"));
          }),
      }}
     
    />
    
    );
  }
}
export default withRouter(ReservedDatesTable);
