import React, { Component } from "react";
import { connect } from "react-redux";
import Material_Table, { MTableToolbar } from "material-table";
import { withRouter, Link } from "react-router-dom";
import { getBookings, editBooking, getReceipts, getIncomes } from "actions";
import moment from "moment";
import { Check, Clear } from "@material-ui/icons";
import { Chip } from "@material-ui/core";
import Info from "../../../components/Typography/Info";
import FinanceGeneralTable from "../../../components/Finance/FinanceGeneralTable";

class FinanceIncomesTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
      incomeColumns: [
        {
          title: "رقم الحجز",
          field: "booking.no",
        },
        { title: "المدفوع", field: "pricePaid" },
        {
          title: "تاريخ الحجز",
          field: "createdAt",
          render: (rowData) => moment(rowData.createdAt).format("YYYY/MM/DD"),
        },
        {
          title: "الصافي لكم ",
          field: "companyProfit",
        },
        {
          title: "اسم المستخدم",
          field: "customer.name",
        },
      ],

      totalPrice: 0,
      totalProfit: 0,
      totalCut: 0,
    };
  }
  renderToolbar = (props) => (
    <div>
      {console.log(props)}

      <MTableToolbar {...props} />
      <div
        style={{
          padding: "0px 10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h5 style={{ margin: "0" }}>ما تم دفعه</h5>
        <Chip
          label={this.state.totalPrice}
          color="secondary"
          style={{ margin: "0 12px" }}
        />
        <h5 style={{ margin: "0" }}>صافي شركتكم</h5>
        <Chip
          label={this.state.totalProfit}
          color="secondary"
          style={{ margin: "0 12px" }}
        />
      </div>
    </div>
  );
  renderDetailPanel = (rowData) => {
    return (
      <div
        style={{
          fontSize: 10,
          textAlign: "right",
          color: "white",
          backgroundColor: "#43A047",
        }}
      >
        {rowData.info}
      </div>
    );
  };
  setStates = (res) => {
    this.setState({ totalPrice: res.totalPrice, totalProfit: res.totalProfit });
  };
  render() {
    return (
      <FinanceGeneralTable
        filterData={this.props.filterData}
        title="قائمة الارباح"
        columns={this.state.incomeColumns}
        getList={getIncomes}
        renderToolbar={this.renderToolbar}
        renderDetailPanel={this.renderDetailPanel}
        setStates={this.setStates}
        type="Entertainment"
      />
    );
  }
}

export default withRouter(FinanceIncomesTable);
