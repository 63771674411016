import React, { Component } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { withRouter, Link } from "react-router-dom";
import { getBookings, editBooking } from "actions";
import moment from "moment";
import { Check, Clear } from "@material-ui/icons";

class ClinicsMaterialTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }
  componentDidMount() {
     
    // if (this.props.date) {
    //   this.setState({
    //     date:this.props.date
    //   });
    // }
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.date !== this.props.date
    
    ) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }
  renderColumn = (rowData) => (
    <Link className="link" to={rowData.to}>
      {rowData.value}
    </Link>
  );
  render() {
    return (
      <MaterialTable
        title="Refresh Data Preview"
        tableRef={this.tableRef}
        columns={[
          {
            title: "المستخدم",
            field: "customer.name",

            render: (rowData) =>
              this.renderColumn({
                to: "/clinics",
                value: rowData.customer.name,
              }),
          },
          {
            title: "وقت ",
            field: "time",
            render: (rowData) =>
              rowData.time,
          },

          {
            title: " المنتج",
            field: "product.name",
            render: (rowData) =>
              this.renderColumn({
                to: {
                  pathname: "/clinics/products",
                  state: { searchValue: `${rowData.product.name}` },
                },
                value: rowData.product.name,
              }),
          },
          { title: "دفع", field: "paid" },
          {
            title: "باقي عنده",
            field: "totalPrice",
            render: (rowData) => rowData.totalPrice - rowData.paid,
          },
          {
            title: "تم الدفع",
            field: "fullyPaid",
            lookup: { true: <Check />, false: <Clear /> },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
             
            this.props
              .getBookings(
                query.page,
                query.pageSize,
                query.orderBy,
                query.orderDirection,
                query.filters,
                this.props.date,
                "bookClinic"
              )
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                 
                alert("err");
              });
          })
        }
        editable={{
          disabled: true,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              // resolve();
              // this.tableRef.current &&
              // this.tableRef.current.onQueryChange()
               
              console.log(oldData);
              console.log(Boolean(newData.fullyPaid));
              this.props
                .editBooking(newData, oldData._id, "bookClinic")
                .then((res) => {
                   
                  resolve(res);
                  console.log(this.tableRef.current);
                })
                .catch((err) => reject());
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              oldData.cancelled = true;
              this.props
                .editBooking(oldData, oldData._id, "bookClinic")
                .then((res) => resolve())
                .catch((err) => alert("eee"));
            }),
        }}
        detailPanel={[
          {
            tooltip: "Show Name",
            render: (rowData) => {
              return (
                <div
                  style={{
                    fontSize: 10,
                    textAlign: "right",
                    color: "white",
                    backgroundColor: "#43A047",
                  }}
                >
                  {rowData.date}
                </div>
              );
            },
          },
          {
            icon: "account_circle",
            tooltip: "Show Surname",
            render: (rowData) => {
              return (
                <div
                  style={{
                    fontSize: 100,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#E53935",
                  }}
                >
                  {rowData.surname}
                </div>
              );
            },
          },
        ]}
        options={{
          filtering:true,
          rowStyle: (rowData) =>
            rowData.cancelled ? { backgroundColor: "#eee" } : null,

          exportButton: true,
          exportCsv: (columns, data) => {
            window.print();
          },
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getBookings,
  editBooking,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClinicsMaterialTable));
